import React from 'react';

var Microsoft

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: props.from,
      to: props.to,
      cities: props.cities,
      delivered: props.delivered
    }
    const bing_maps_key = props.bing_maps_key || 'AlKZKycn8KtPKl1SsECeBjFgoob4S35invw8m2jN267XaQWX_-hXbpWZsjvhNhJo'
    const scriptURL = `https://www.bing.com/api/maps/mapcontrol?key=${bing_maps_key}&callback=loadMap`;
    if(document.querySelector('script[src="' +scriptURL+ '"]') === null){
			this.loadScript(scriptURL);
			window.loadMap = function(){
				Microsoft = window.Microsoft;
        var map = new Microsoft.Maps.Map(document.getElementById('myMap'), {
            showLocateMeButton: false,
            showMapTypeSelector: false,
            center: new Microsoft.Maps.Location(51.9061197, 19.4204499),
            zoom: 6
        });
        if(props.from && props.to){
          Microsoft.Maps.loadModule('Microsoft.Maps.Search', function () {
          	let searchManager = new Microsoft.Maps.Search.SearchManager(map);
            var prom1 = new Promise((resolve) => {
              let toOptions = {
          	    bounds: map.getBounds(),
          	    where: props.to,
          	    callback: function (answer) {
          		    map.entities.push(new Microsoft.Maps.Pushpin(answer.results[0].location, {color: props.delivered?'#00dc1b':'red'}));
          		    resolve(answer.results[0].location)
          	    }
              };
            	searchManager.geocode(toOptions);
            })

            let city_to_location = {}
            let route = props.cities.map(c => {
              return new Promise((resolve) => {
                let options = {
                  bounds: map.getBounds(),
                  where: c,
                  callback: function (answer) {
                    if(!(c in city_to_location)){
                      map.entities.push(new Microsoft.Maps.Pushpin(answer.results[0].location, {color: '#00e927'}));
                      city_to_location[c] = answer.results[0].location
                      resolve(answer.results[0].location)
                    }
                  }
                };
                searchManager.geocode(options);
              })
            })

          	var prom2 = new Promise((resolve) => {
            	let fromOptions = {
            	    bounds: map.getBounds(),
            	    where: props.from,
            	    callback: function (answer) {
            		    map.entities.push(new Microsoft.Maps.Pushpin(answer.results[0].location, {color: '#00dc1b'}));
            		    resolve(answer.results[0].location)
            	    }
                };
            	searchManager.geocode(fromOptions);
            })
            Promise.all([prom1, prom2, ...route]).then(function(values) {
              let from = values[1]
              let to = values[0]
              let route = values.slice(2)

              let latmax = Math.max(...values.map(v => v.latitude))
              let longmax = Math.max(...values.map(v => v.longitude))
              let latmin = Math.min(...values.map(v => v.latitude))
              let longmin = Math.min(...values.map(v => v.longitude))
              let mapWidth = map._rootElement[0].offsetWidth
              let mapHeight = map._rootElement[0].offsetHeight
              let zoom1 = Math.log(360.0 / 256.0 * (mapWidth - 100) / (longmax - longmin)) / Math.log(2);
              let zoom2 = Math.log(180.0 / 256.0 * (mapHeight - 100) / (latmax - latmin)) / Math.log(2);
              let zoomLevel = (zoom1 < zoom2) ? zoom1 : zoom2;
              map.setView({
                // bounds: Microsoft.Maps.LocationRect.fromEdges(latmax,longmin,latmin,longmax),
                center: new Microsoft.Maps.Location((latmax + latmin)/2, (longmax+longmin)/2),
                zoom: zoomLevel - 0.5
              });

              let lines = []
              let last = from
              for (let l=0; l<lines.length; l++){
                lines.push([last, route[l]])
                last = route[l]
              }
              lines.push([last, to])

              let callback = function (l, color) {
                let path = Microsoft.Maps.SpatialMath.getGeodesicPath([lines[l][0], lines[l][1]], 360);
                let line = new Microsoft.Maps.Polyline(path, {
                  strokeColor: color,
                  strokeThickness: 3
                });
                map.entities.push(line)

                if (path.length >= 2) {
                  let arrow = Math.floor(path.length / 2)
                  var heading = Microsoft.Maps.SpatialMath.getHeading(path[arrow+1], path[arrow]) - 180;
                  var arrowPin = new Microsoft.Maps.Pushpin(path[arrow], {
                      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="32" width="32"><path d="M10.5 31 L16 16 21.5 31" style="stroke:'+color+';stroke-width:3px;fill:'+color+';" transform="rotate(' + heading + ', 16, 16)"/></svg>',
                      anchor: new Microsoft.Maps.Point(16, 16)
                  });
                  map.entities.add(arrowPin)
                }
              }

              for (let l=0; l<lines.length; l++){
                let color = (parseInt(l)+1 >= lines.length && !props.delivered?'red':'#00dc1b')
                Microsoft.Maps.loadModule('Microsoft.Maps.SpatialMath', function(){
                  callback(l, color)
                })
              }
            })
          })
        }
			}
		}
  }

  loadScript(url){
	    var script = document.createElement('script')
	    script.type = 'text/javascript';
	    script.async = true;
	    script.defer = true;
	    script.src = url;
	    document.getElementsByTagName('head')[0].appendChild(script);
	}

  render() {
    return(
      <div id="myMap"></div>
    )
  }
}

export default Map;
