import { withRouter } from 'react-router-dom'
import React from 'react'
import Popup from 'react-popup'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { ReactComponent as WorldIcon } from '../assets/world.svg'
import Map from './Map'
import Message from './Message'
import StatusBar from './StatusBar'
import MessageComposer from './MessageComposer'
import { revealName, printInvoice, toRma } from '../actions/OrderActions'
require('./Prompt')

function lineRow(line){
  return (
    <tr key={line.id}>
      <td>
        {line.auction_link &&
          <a href={line.auction_link} target="__blank">{line.name} <span className="allegro_logo">allegro</span></a>
        }
        {!line.auction_link &&
          <strong>{line.name}</strong>
        }
      </td>
      <td className="d-none d-md-table-cell">
        { line.image &&
          <img src={'data:image/png;base64, '+line.image} alt="product"/>
        }
      </td>
      <td>{line.quantity}</td>
      <td className="right d-none d-md-table-cell">{line.price_unit}</td>
      <td className="right d-none d-md-table-cell">{line.netto}</td>
      <td className="right d-none d-md-table-cell">{line.brutto}</td>
      <td className="right d-md-none mobile-prices">
        <small>{intl.get('netto')}</small><br/>
        {line.netto}<br/>
        <br/>
        <small>{intl.get('brutto')}</small><br/>
        {line.brutto}
      </td>
    </tr>
  )
}

function getMessage(message) {
  return (
    <Message key={message.id} message={message} />
  );
}

const mapStateToProps = state => ({
  order: state.order.data,
  token: state.auth.session.token
})

const mapDispatchToProps = dispatch => ({
  revealNameAction: (name, client_token) => dispatch(revealName(name, client_token)),
  toRmaAction: (name, client_token) => dispatch(toRma(name, client_token)),
  printInvoiceAction: (order_id, name) => dispatch(printInvoice(order_id, name))
})

class OrderComponent extends React.Component {

  componentDidMount() {
    const { getOrderAction, authenticated, order_id, order } = this.props
    if(authenticated && !order) getOrderAction(false, order_id)
  }

  loadInvoice = (event) =>{
    event.preventDefault()
    const { order, printInvoiceAction } = this.props
    if(order.revealed){
      printInvoiceAction(order.id, order.invoice_name)
    } else {
      Popup.plugins().prompt(intl.get('Podaj jedną z następujących wartości: login serwisu aukcyjnego, imię i nazwisko.'),
        function (name) {
          printInvoiceAction(order.id, name)
        })
    }
    return false
  }

  revealNamePopup = (event) =>{
    const {revealNameAction} = this.props
    const token = this.props.match.params.token
    Popup.plugins().prompt(intl.get('Podaj jedną z następujących wartości: login serwisu aukcyjnego, imię i nazwisko.'),
      function (name) {
        revealNameAction(name, token)
      })
  }

  toRma = (event) =>{
    const {toRmaAction, order} = this.props
    if(!order.revealed){
      event.preventDefault()
      const token = this.props.match.params.token
      Popup.plugins().prompt(intl.get('Podaj jedną z następujących wartości: login serwisu aukcyjnego, imię i nazwisko.'),
        function (name) {
          toRmaAction(name, token)
        })
      return false
    }
  }

  render() {
    const { order } = this.props
    const token = this.props.match.params.token
    const rma_url = 'http://'+window.location.hostname.replace('podsumowanie', 'formularz')+
                    '/?order='+order.name+'&token='+order.rma_token
    if(order){
      let cities = [...new Set(order.status_list.map(s => {
        if(s.city) {return s.city}
        else return null
      }).filter(s => { return s != null}))]
      let delivered = false
      if(order.status === 'Delivered'){
        delivered = true
        if(cities.length > 0 && cities.slice(-1)[0] === order.delivery_city){
          cities = cities.slice(0, -1)
        }
      }
      return (
        <div className="elem-view order_card">
          <div className="row order_header">
            <div className="col-sm-6">
              <h2>{intl.get('Zamówienie')} {order.name}</h2>
            </div>
            <div className="col-sm-6">
              <StatusBar/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-5">
              <h4 className="underline">{intl.get('Adres dostawy')}</h4>
              <div className="delivery_name f_group">
                <label>{intl.get('Imię i nazwisko')}:</label>
                <div>{order.delivery_name}</div>
              </div>
              <div className="delivery f_group">
                <label>{intl.get('Adres')}:</label>
                <div>
                  <div>{order.delivery_street}</div>
                  <div>{order.delivery_zip} {order.delivery_city}</div>
                </div>
              </div>
              <div className="delivery_country f_group">
                <label><WorldIcon/>{intl.get('Kraj')}:</label>
                <div>{order.delivery_country}</div>
              </div>
            </div>
            <div className="col-sm-2"></div>
            <div className="col-sm-5">
              <h4 className="underline">{intl.get('Dane do faktury')}</h4>
              <div className="invoice_name f_group">
                <label>{intl.get('Imię i nazwisko')}:</label>
                <div>{order.invoice_name}</div>
              </div>
              <div className="delivery f_group">
                <label>{intl.get('Adres')}:</label>
                <div>
                  <div>{order.invoice_street}</div>
                  <div>{order.invoice_zip} {order.invoice_city}</div>
                </div>
              </div>
              { order.invoice_vat &&
                <div className="invoice_vat f_group">
                  <label>{intl.get('NIP')}:</label>
                  <div>{order.invoice_vat}</div>
                </div>
              }
            </div>
          </div>
          {!order.revealed &&
            <button className="revealName" onClick={this.revealNamePopup}>{intl.get('Wyświetl pełne dane')}</button>
          }
          <hr/>
          <h4>{intl.get('Pozycje zamówienia')}</h4>
          <table className="tab order_lines">
            <thead>
              <tr>
                <th style={{width: '40%'}}>{intl.get('Produkt')}</th>
                <th className="d-none d-md-table-cell">{intl.get('Zdjęcie')}</th>
                <th>{intl.get('Ilość')}</th>
                <th className="right d-none d-md-table-cell">{intl.get('Cena jednostkowa')}</th>
                <th className="right d-none d-md-table-cell">{intl.get('Wartość netto')}</th>
                <th className="right d-none d-md-table-cell">{intl.get('Wartość brutto')}</th>
                <th className="right d-md-none">{intl.get('Wartość')}</th>
              </tr>
            </thead>
            <tbody>
              {order.lines.map(line => lineRow(line))}
            </tbody>
            <tfoot>
              <tr className="d-md-none">
                <td colSpan="2"/>
                <th className="right"><strong>{order.amount_total}</strong></th>
              </tr>
              <tr className="d-none d-md-table-row" style={{backgroundColor: '#fff'}}>
                <td colSpan="4"/>
                <th className="right"><strong>{order.amount_untaxed}</strong></th>
                <th className="right"><strong>{order.amount_total}</strong></th>
              </tr>
            </tfoot>
          </table>
          <hr/>
          <div className="row">
            <div className="col-md-6">
              <h4>{intl.get('Przesyłka')}:</h4>
              <div className="carrier f_group">
                <label>{intl.get('Metoda dostawy')}:</label>
                <div>{order.carrier}</div>
              </div>
              <div className="waybill f_group">
                <label>{intl.get('Numer listu przewozowego')}:</label>
                <div>{order.waybill}</div>
              </div>
              {/*TODO ODKOMENTOWAC JAK BEDZIEMY MIELI POBIERANIE STATUSOW*/}
              {/*<div className="status f_group">*/}
              {/*  <label>{intl.get('Status')}:</label>*/}
              {/*  <div>{order.status} {order.api_status ? '('+order.api_status+')' : ''}</div>*/}
              {/*</div>*/}
              {/*{order.status_list.length === 0 &&*/}
              {/*  <div className="status_date f_group">*/}
              {/*    <label>{intl.get('Ostatnia zmiana statusu')}:</label>*/}
              {/*    <div>{order.last_status_update}</div>*/}
              {/*  </div>*/}
              {/*}*/}
              {/*{order.status_list.length > 0 &&*/}
              {/*  <div className="historyWrap">*/}
              {/*    /!*<Statuses list={order.status_list}/>*!/*/}
              {/*  </div>*/}
              {/*}*/}
            </div>
            <div className="col-md-6">
              <Map to={(order.delivery_street && order.delivery_city)?order.delivery_street+', '+order.delivery_city:false}
                   from={order.company_address}
                   cities={cities}
                   delivered={delivered}
                   bing_maps_key={order.bing_maps_key}/>
            </div>
          </div>
          { order.has_invoice &&
            <button className="invoice" onClick={this.loadInvoice}>{intl.get('Pobierz fakturę')}</button>
          }

          { order.rma_token &&
            <a className="rma button" href={rma_url} target="_blank" onClick={this.toRma}>{intl.get('Zgłoś zwrot lub reklamację')}</a>
          }
          <hr/>
          { order &&
            <MessageComposer order={order} token={token} />
          }
          { order && order.messages && order.messages.length > 0 &&
            <div className="messages">
              {order.messages.map(message => getMessage(message))}
            </div>
          }
          { order && (!order.messages || order.messages.length === 0) &&
            <div className="no-messages">{intl.get('To zamówienie nie zawiera żadnych wiadomości')}</div>
          }
        </div>
      )
    } else {
      return null
    }
  }
}
const Order = connect(mapStateToProps, mapDispatchToProps)(OrderComponent)
export default withRouter(Order)
